import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import MainVisual from "../components/main-visual"
import IconArrow from "../assets/arrow-right.svg"

const OurCapabilitiesPage = () => {
  const firstSection = React.useRef()
  const scrollDown = () => {
    window.scrollTo({
      top: firstSection.current.offsetTop,
      behavior: 'smooth',
    })
  }

  return (
    <Layout>
       <MainVisual
        title="What We Can Do For You?"
        description="Elevate Your Business with Our Solutions"
        buttonText="See Our Capabilities"
        buttonOnClick={scrollDown}
      >
        <StaticImage src="../images/cap-main-visual.jpg" className="w-full" alt="main visual"  />
      </MainVisual>
      <section className="bg-white py-8 md:py-20" ref={firstSection}>
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-none md:grid-flow-col gap-6 md:gap-16 items-center">
            <StaticImage src="../images/cap-1.png" className="flex-shrink-0 md:order-last max-h-60 max-w-sm" layout="constrained" objectFit="contain" alt="about" />
            <div className="text-center md:text-left">
              <div className="text-secondary text-lg md:text-2xl font-semibold mb-2">Business Analytics</div>
              <h4 className="text-primary text-xl md:text-4xl font-semibold mb-2">Gain insights from your data</h4>
              <p className="text-sm md:text-base mb-3 md:mb-6">
              Every day, your business generates data as part of its daily activities, be it sales and marketing activities, inventory management, manufacturing processes, transactions with vendors, or other business processes. 
              Utilized well, all this data can improve your business’ profitability and efficiency, as well as help you identify new business opportunities. 
              Our team of professionals can help you to:
                </p>
                <ul className="circleListStyle md:text-base mb-3 md:mb-6">
                  <li>Identify the various types of data your business processes generate.</li>
                  <li>Identify how this data can be used to solve your business problems.</li>
                  <li>Conduct studies to answer your business questions and to explore new business opportunities.</li>
                  <li>Build analytics pipelines and dashboards to help your team use this data in their daily work.</li>
                </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light py-8 md:py-20">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-none md:grid-flow-col gap-6 md:gap-16 items-center">
            <StaticImage src="../images/cap-2.png" className="flex-shrink-0 max-h-60 max-w-sm" layout="constrained" objectFit="contain" alt="about" />
            <div className="text-center md:text-left">
              <div className="text-secondary text-lg md:text-2xl font-semibold mb-2">Development of Machine Learning Systems</div>
              <h4 className="text-primary text-xl md:text-4xl font-semibold mb-2">Apply intelligent automation to improve your business processes</h4>
              <p className="text-sm md:text-base mb-3 md:mb-6">
                We all understand that business have processes that are crucial but repetitive. Be it analyzing sales to predict required inventory levels 
                or monitoring business processes for unusual events that require management intervention, such tasks are integral to business operations. 
                Unfortunately, these tasks often also involve uncertainties and changing situation on the ground, which hinders straightforward automation.</p>
              <p className="text-sm md:text-base">
                Our team can help you build and deploy machine learning systems that learn from your data and automate tasks intelligently. 
                With our development capability, we can customize the system to suit your business objectives as well as operational requirements, 
                such as use of specific cloud service provider and seamless integration with your existing systems.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-primary py-8 md:py-20">
        <div className="relative container z-10 text-center">
        <div className="text-white text-2xl md:text-4xl mb-2">What can we do for you?</div>
          <div className="text-white text-lg md:text-2xl mb-6">Tell us your pain and we'll solve them for you.</div>
          <Link to="/contact-us" className="inline-flex items-center bg-transparent border border-white px-4 py-2 text-white">
            Let's Talk <IconArrow className="inline w-5 h-5 fill-white ml-2" />
          </Link>
        </div>
        <div className="absolute top-0 left-0 right-0 mx-auto text-center">
          <StaticImage src="../images/home-section-bg-2.png" alt="section"  />
        </div>
      </section>
    </Layout>
  )
}

export default OurCapabilitiesPage

export const Head = () => (
  <>
    <title>DPV - Capabilities</title>
    <meta name="description" content="Elevate Your Business with Our Solutions" />
  </>
)

